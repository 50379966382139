<template>
    <div v-if="value.content.type == 'deadman'">
                    Alarm na&nbsp;<strong>{{ value.content.deadman/(value.content.divider || 1000) }} {{(value.content.divider||1000) == 60000?'min.':'sec.'}}</strong>&nbsp;geen communicatie
                </div>
                <div v-else-if="value.content.type == 'listitems'">
                    <v-chip-group v-if="value.content.severities">
                        <v-menu open-on-hover offset-y v-for="severity in AlarmRule.listItemSeverities" :key="severity.value">
                            <template v-slot:activator="{attrs, on}">
                                <v-chip v-if="value.content.severities[severity.value]" small v-bind="attrs" v-on="on" :color="severity.color" class="black--text">
                            <v-icon small left>mdi-list-box-outline</v-icon>{{ value.content.severities[severity.value].length }}
                        </v-chip>
                            </template>
                            <v-card>
                                    <v-list v-if="value.content.severities && value.content.severities[severity.value] && value.content.severities[severity.value].length > 0" class="transparent" dense>
                                        <v-list-item v-for="(listItem, index) in value.content.severities[severity.value]" :key="index">
                                            <v-list-item-content>
                                                <v-list-item-title>{{ listItem.name }}</v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                <v-card-text v-else>Geen waardes ingesteld</v-card-text>
                            </v-card>
                        </v-menu>
                        
                    </v-chip-group>
                </div>
                <div v-else-if="value.content.type == 'thresholds'" style="padding-left:14px;">
                    <v-chip v-for="severity in AlarmRule.severities" :key="severity.value" style="margin-left:-14px;" :style="severity.value=='ok'?'margin-right:4px;':''" small class="black--text" :color="severity.color" v-if="value.content.severities[severity.value] != null && value.content.severities[severity.value] != ''">
                        {{ value.content.severities[severity.value] }}<v-icon v-if="severity.icon" v-text="severity.icon" small></v-icon>
                    </v-chip>
                </div>
</template>
<script>
import AlarmRule from './../models/alarmRule.js'
    export default {
        name:'eod-alarm-settings',
        props:{
            value: Object
        },
        data: () => ({
            AlarmRule: AlarmRule,
        })
    }
</script>