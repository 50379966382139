<template>
<div v-html="text"></div>
</template>
<script lang="js">
export default{
    name: 'eod-translated',
    props:{
        value: String,
        noTranslate:{
            type: Boolean,
            default: false
        }
    },
    data: () =>  ({
        text: null,
    }),
    async created(){
        this.updateValue(this.value);
    },
    watch:{
        value: {
            handler: function(val){
                this.updateValue(val);
            }
        },
        noTranslate: {
            handler: function(val){
                this.updateValue(this.value);
            }
        }
    },
    methods:{
        async updateValue(val){
            if (val) {
                this.text = await this.$trans(val, !this.noTranslate);
            } else {
                this.text = null;
            }
        }
    }
}
</script>