let users = require('./users');
let teams = require('./teams');
let settings = require('./settings');
let integrations = require('./integrations');
const { Company } = require('@/eodvuecomponents');

module.exports = {
    routes: [
    {
        path: '/organization/general',
        name: 'organization',
        component: () => import('./views/detail.vue'),
        meta:{
            permissions:['organization.general.read']
        },
    },
    ...users.routes,
    ...teams.routes,
    ...settings.routes,
    ...integrations.routes
    ],
    itemObject: Company,
    detail: {
        route: 'organization',
        endpoint: 'companies',
        objectName: 'Company',
        fields: ['id', 'name', 'description', 'defaultService{id}', 'remarks', 'tags{id name color}', 'type', 'vatNumber', 'website', 'iban', 'bic', 'division{id}', 'department{id}', 'nationalIdentificationNumber', 'locations{name description streetAddress postalCode locality region country notes department lat lon}', 'configuration{version settings{key value label isDefault isRoot}}'],
        tabs: [{
                path: 'organization',
                text: 'Algemeen',
                permissions: ['organization.general.menu']
            },
            {
                path: 'organizationUsers',
                text: 'Medewerkers',
                permissions: ['organization.employees.menu']
            },
            {
                path: 'organizationContacts',
                text: 'Contacten',
                permissions: ['organization.contacts.menu']
            },
            {
                path: 'organizationConnectors',
                text: 'Data connectors',
                permissions: ['organization.responsibles.menu']
            },
            {
                path: 'Teams',
                text: 'Teams',
                permissions: ['organization.teams.menu']
            },
            {
                path: 'organizationIntegrations',
                text: 'Koppelingen',
                permissions: ['organization.integrations.menu']
            },
            {
                path: 'organizationSettings',
                text: 'Instellingen',
                permissions: ['organization.settings.menu']
            }
        ],
    },
    users: users,
    teams: teams,
    settings: settings,
    integrations: integrations,
}