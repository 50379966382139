<template>
  <v-card>
    <v-card-title>
      <v-avatar color="primary" size="40" class="elevation-3 mr-4">
        <v-icon dark v-if="value.id">
          mdi-pencil-outline
        </v-icon>
        <v-icon dark v-else>
          mdi-plus
        </v-icon>
      </v-avatar>
      <span class="headline" v-text="value.id ? $t('Gebruiker aanpassen') : $t('Gebruiker toevoegen')"></span>
      <v-spacer></v-spacer>
      <v-btn small icon @click="onCancel"><v-icon>mdi-close</v-icon></v-btn>
    </v-card-title>
    <div class="px-5">
      <v-tabs v-model="tab" background-color="transparent" class="mb-4">
        <v-tab key="general" href="#general">
          <span>Algemeen</span>
        </v-tab>
        <v-tab key="address" href="#address">
          <span>Adres</span>
        </v-tab>
        <v-tab key="access" href="#access">
          <span>Toegang</span>
        </v-tab>
        <v-tab key="notifications" href="#notifications">
          <span>Notificaties</span>
        </v-tab>
      </v-tabs>
    </div>
    <div class="px-4">
      <v-alert type="error" v-if="errors.length > 0" dense class="mb-3">
        <ul>
          <li v-for="(error, index) in errors" :key="'error_' + index">{{ error }}</li>
        </ul>
        <v-list dense dark color="transparent">
          <v-list-item v-for="(user, index) in existingUsers" :key="'existing_user_' + index" @click="returnExistingItem(user)">
            <v-list-item-content>
              <v-list-item-title>Bestaande gebruiker '{{ user.username }} ({{ user.email }})' toevoegen</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-alert>
    </div>
    <v-card-text style="position:relative;">
      <v-form ref="form" lazy-validation>
        <v-tabs-items :value="tab">
          <v-tab-item value="general" transition="fade-transition" reverse-transition="fade-transition">
            <eod-user-detail :roles="roles" :value="value" :username-exists="usernameExists" @input="updateUser"
              :role="showRole"></eod-user-detail>
          </v-tab-item>
          <v-tab-item value="address" transition="fade-transition" reverse-transition="fade-transition">
            <eod-user-address v-if="!isGuest()" v-model="value"></eod-user-address>
            <eod-depot-detail v-else-if="item.depot" :key="updateDepot" :value="item.depot"
              @input="val => item.depot = val" simple></eod-depot-detail>
          </v-tab-item>
          <v-tab-item value="access" transition="fade-transition" reverse-transition="fade-transition">
            <eod-objects-treeview v-if="item" :classes="['COMPANY', 'PROJECT']" :selectable="['COMPANY','PROJECT']" :selected="item.getRelatedItems()" multiple @selectionChanged="selection => item.setAccess(selection)"></eod-objects-treeview>
          </v-tab-item>
          <v-tab-item value="notifications" transition="fade-transition" reverse-transition="fade-transition">
            <eod-user-notifications v-if="item" :value="item"></eod-user-notifications>
          </v-tab-item>
        </v-tabs-items>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <div class="w-100 px-4 grey lighten-4 py-2">
        <v-checkbox id="chk_user_send_mail" class="my-0" v-if="!value.id" v-model="options.sendMail" label="Verstuur een e-mail met gebruikersnaam en wachtwoord naar de gebruiker."></v-checkbox>
        <eod-textarea rows="3" class="white" v-if="!value.id && options.sendMail" v-model="options.sendMailText" info="Bericht om mee te sturen met de e-mail." placeholder="Extra info (optioneel)"></eod-textarea>
      </div>
      <v-divider></v-divider>
    <v-card-actions class="grey lighten-3 py-3 px-3">
      <v-spacer></v-spacer>
      <v-btn @click="onCancel" rounded class="mr-2" text>
        Annuleren
      </v-btn>
      <v-btn id="btn_save_user" color="secondary" @click="onCreate" :loading="saving" rounded depressed>
        <template v-if="value.id">
          <v-icon>mdi-content-save</v-icon>
          Opslaan
        </template>
        <template v-else>
          <v-icon>mdi-plus</v-icon>
          Toevoegen
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import eodUserDetail from './eod-user-detail.vue';
import eodUserAddress from './eod-user-address.vue';
import eodDepotDetail from './eod-depot-detail.vue';
import eodUserNotifications from './eod-user-notifications.vue';
import eodObjectsTreeview from './eod-objects-treeview.vue'

export default {
  name: 'AddContacts',
  components: {
    eodUserDetail,
    eodUserAddress,
    eodDepotDetail,
    eodUserNotifications,
    eodObjectsTreeview
  },
  props: {
    roles: Array,
    value: Object,
    activeTab: Number,
    showRole: {
      default: true,
      type: Boolean
    },
  },
  data: () => {
    return {
      tab: 'general',
      item: null,
      updateDepot: 930923,
      emailExists: false,
      usernameExists: false,
      existingUsers: [],
      errors: [],
      saving: false,
      options: {
        sendMail: false,
        sendMailText:null
      }
    }
  },
  mounted() {
    this.item = this.value;
    if (!this.item.depot) {
      this.updateDepotProperty('name', this.item.username);
    }

    if(!this.item.projects){
      this.item.projects = [];
    }

    if(!this.item.companies){
      this.item.companies = [];
    }

    const defaultSendMailSetting = this.$eod.getOrganizationSetting('organization.contacts.defaultSendUsernameAndPassword');
    if(defaultSendMailSetting){
      this.options.sendMail = defaultSendMailSetting.value == '1'?true:false;
    }

    if(!this.item.attributes){
      const def = this.$eod.getDefaultLanguage();
      this.item.attributes = {
          locale: [def.value]
        };
    }

    if(this.activeTab){
      this.tab = this.activeTab;
    }
  },
  watch:{
    activeTab(val){
      this.tab = val;
    }
  },
  methods: {
    updateDepotProperty(property, value) {
      if (this.isGuest() && !this.item.depot) {
        let country = '';
        const defaultCountrySetting = this.$eod.getOrganizationSetting('organization.contacts.defaultCountry');
        if (defaultCountrySetting && defaultCountrySetting.value && defaultCountrySetting.value != '') {
          country = defaultCountrySetting.value;
        }
        this.item.depot = {
          name: this.item.username,
          category: 'home',
          country: country
        };
      } else if(this.item.depot) {
        this.item.depot[property] = value;
      }

      return null;
    },
    updateUser(val) {
      this.item = val;
      this.updateDepotProperty('name', val.username)
      this.updateDepot++;
    },
    isGuest() {
      if (this.item && this.item.assignedRealmRoles) {
        for (let i = 0; i < this.item.assignedRealmRoles.length; i++) {
          const role = this.item.assignedRealmRoles[i];
          if(role.name == 'contributor' || role.name == 'responsible'){
            return true;
          }
        }
      }
    },
    async onCreate() {
      this.errors = [];
      this.existingUsers = [];
      var valid = this.$refs.form.validate();
      if (valid) {
        // Check if username or e-mail exists
        if (!this.item.id) {

          await this.$eod.checkExisting('users', ['id', 'email', 'username'], {
            search: this.item.username
          }).then(response => {
            if (response.data.data.checkExistingUsers && response.data.data.checkExistingUsers.edges[0]) {
              this.errors.push('Er bestaat reeds een gebruiker met gekozen gebruikersnaam!');
              this.usernameExists = true;
              if(!this.existingUsers.map((item) => item.id).includes(response.data.data.checkExistingUsers.edges[0].id)){
                this.existingUsers.push(response.data.data.checkExistingUsers.edges[0]);
              }
              
            }else{
              this.usernameExists = false;
            }

            if (response.data.data.email) {
              this.errors.push('Er bestaat reeds een gebruiker met gekozen e-mailadres!');
            }
          });

          await this.$eod.checkExisting('users', ['id', 'email', 'username'], {
            search: this.item.email
          }).then(response => {
            if (response.data.data.checkExistingUsers && response.data.data.checkExistingUsers.edges[0]) {
              this.errors.push('Er bestaat reeds een gebruiker met gekozen e-mailadres!');
              if(!this.existingUsers.map((item) => item.id).includes(response.data.data.checkExistingUsers.edges[0].id)){
                this.existingUsers.push(response.data.data.checkExistingUsers.edges[0]);
              }
            }
          });
        }

        if (this.errors.length > 0) {
          return false;
        }

        this.saveItem(this.item);
      } else {
        this.errors = this.getFormErrors();
      }
    },
    async saveItem(user) {
      this.saving = true;

      let depotId = null;

      try {
        if (user.depot && user.depot.streetAddress && user.depot.locality) {
          depotId = await this.$eod.save('Depot', user.depot).then(async response => {
            if (response.data.data.createDepot) {
              return response.data.data.createDepot.id;
            } else if (response.data.data.updateDepot) {
              return response.data.data.updateDepot.id;
            }
            return null;
          });
        }

        const data = await user.getSaveData();
        if (depotId) {
          data.depotId = depotId;
        }

        if(!this.value.id && this.options.sendMail){
          data.credentials = {
              temporary: true,
              type:'password',
              value: this.$helper.generatePassword()
          };
        }

        this.item = await this.$eod.save('User', data).then(async response => {
          if (response.data.data && response.data.data.createUser) {
            user.id = response.data.data.createUser.id;

            if(this.options.sendMail){
              await this.$eod.sendMail([user.email], 'UserCreatedMail', {
                user_id: user.id,
                password: data.credentials.value,
                text: this.options.sendMailText
              });
            }

            return user;
          } else if (response.data.data && response.data.data.updateUser) {
            return user;
          } else if (response.data.errors) {
            throw response.data.errors;
          }
        });

        this.$emit('input', this.item);
        this.$emit('onSaved', this.item);
      } catch (errors) {
        if(errors && errors[0]){
          this.errors = [errors[0].message];
          this.usernameExists = true;
        }
        console.error('error!', errors);
      } finally {
        this.saving = false;
      }
    },
    returnExistingItem(user){
      this.$emit('input', user);
      this.$emit('onSaved', user);
    },
    getFormErrors() {
      const errors = [];
      this.$refs.form.inputs.forEach(e => {
        if (e.errorBucket && e.errorBucket.length) {
          errors.push(...e.errorBucket);
        }
      });
      return errors;
    },
    onCancel() {
      this.$emit('onCancel');
    }
  }
}
</script>