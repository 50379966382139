export default class Tool {
    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam',
        'type': 'property',
        'settings': {
            'fields': ['name'],
            'title': '{name}',
        },
        'filter': 'tree_objects_view.name'
    },
    {
        'icon': 'mdi-file-sign',
        'label': 'Type',
        'type': 'module',
        'settings': {
            'module': 'toolTypes',
            'fields': ['name'],
            'title': '{name}',
        },

        'filter': 'tree_objects_view.type_id'
    },
    {
        'icon': 'mdi-alarm',
        'label': 'Wijzigingsdatum',
        'type': 'date-between',
        'filter': 'updatedAt'
    },
    {
        filter:'tree_objects_view.id',
        icon:'mdi-folder-open-outline',
        operator: '=',
        filterMethod:'where',
        label:'Depot',
        settings:{
            fields:['name'],
            module:'resolveDepots',
            title:'{name}'
        },
        type:'module'
    }
    ];
    static exportFields = ['id', 'name', 'description', 'depot{name}', 'toolType{name}'];
    static moduleName = 'tools';
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getSaveData() {
        let data = Object.assign({}, this);
        return data
    }
}