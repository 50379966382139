let User = require('./../../eodvuecomponents/src/models/user').default;

module.exports = {
    routes: [
        {
            path: '/profile/:tab?',
            name: 'profile',
            component: () => import('./views/detail.vue'),
        }],
    itemObject: class Me extends User {
        async getSaveData() {
            const data = await super.getSaveData();
            delete data.assignedRealmRoles;
            return data;
        }
    },
    detail: {
        route: 'profile',
        endpoint: 'me',
        objectName: 'Me',
        fields: ['id', 'firstName', 'lastName', 'username', 'email', 'alarmTemplates{id}', 'depot{id name description streetAddress postalCode locality region country category}', 'notificationSettings', 'assignedRealmRoles{id name}', 'attributes{phone_number locale jobtitle extra_phone notes description}'],
        tabs: [{
            tab: 'general',
            text: 'Algemeen'
        },
        {
            tab: 'address',
            text: 'Adres',
            visible(item, eod) {
                
                    let group = eod.getUser().getRoleGroup();
                    if (group != 'collaborators' && group != 'eod') {
                        return false;
                    }

                return true;
            }
        },
        {
            tab: 'notifications',
            text: 'Notificaties'
        },
        {
            tab: 'integrations',
            text: 'Koppelingen'
        }],
        getTitle(item) {
            return item.firstName + ' ' + item.lastName;
        }
    }
}