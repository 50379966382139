export default {
    readFile(file){
        return new Promise((resolve, reject) => {
             var reader = new FileReader();
             reader.onload = function (e) {
                 resolve(e.target.result);
             }
             reader.readAsDataURL(file);
        });
    },
    resize(src, width, quality) {

        if (!quality){
            quality = 0.8;
        }

        const self = this;
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = function () {
                let url = self.stepped_scale(img, width, quality);
                resolve(url);
            }
            img.addEventListener("error", function(event) {
                reject(event);
            });
            img.src = src;
        });
    },
    stepped_scale(img, width, quality) {
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext("2d"),
            oc = document.createElement('canvas');
            //octx = oc.getContext('2d');
        
        if(img.height > img.width){
            width = img.width/img.height*width;
        }

        canvas.width = width; // destination canvas size
        canvas.height = canvas.width * img.height / img.width;

        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL("image/webp", quality);
    }
}