const User = require('../../../plugins/user');

module.exports = {
    routes: [{
        path: '/organization/teams',
        name: 'Teams',
        component: () => import('./views/index.vue'),
        meta: {
            permissions: ['organization.teams.read']
        }
    }, {
        path: '/organization/teams/detail/:id?',
        name: 'Team',
        component: () => import('./views/detail.vue'),
        meta: {
            permissions: ['organization.teams.update']
        }
    }],
    itemObject: class {
        constructor(item) {
            if (item) {

                for (const key in item) {
                    if (item.hasOwnProperty(key)) {
                        this[key] = item[key];

                        if (key == 'users' && this[key]) {
                            for (let i = 0; i < this[key].length; i++) {
                                this[key][i] = new User(this[key][i]);
                            }
                        }

                        if (key == 'companies' || key == 'projects') {
                            if (this[key] == null) {
                                this[key] = [];
                            }
                        }
                    }
                }
            }
        }
        getSaveData() {
            
            let data = Object.assign({}, this);

            if (data.users) {
                for (let i = 0; i < data.users.length; i++) {
                    data.users[i] = {
                        id: data.users[i].id,
                        organizationId: data.organizationId
                    }
                }
            }
            
            if(data.companies){
                for (let i = 0; i < data.companies.length; i++) {
                    data.companies[i] = {
                        id: data.companies[i].id,
                        organizationId: data.organizationId
                    }
                }
            }
            
            if (data.projects) {
                for (let i = 0; i < data.projects.length; i++) {
                    data.projects[i] = {
                        id: data.projects[i].id,
                        organizationId: data.organizationId
                    }
                }
            }
  
            return data;
        }
    },
        index: {
            route: 'Teams',
            endpoint: 'groups',
            fields: ['id', 'name', 'createdAt'],
            headers: [{
                    text: 'Naam',
                    value: 'name',
                    component: () => import('./../../default/columns/link.vue')
                },
                {
                    text: 'Aangemaakt',
                    width: 250,
                    value: 'createdAt',
                    component: () => import('./../../default/columns/date.vue')
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('./../../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
            tabs: [{
                path: 'organization',
                text: 'Algemeen',
                permissions: ['organization.general.menu']
            },
            {
                path: 'organizationUsers',
                text: 'Medewerkers',
                permissions: ['organization.employees.menu']
            },
            {
                path: 'organizationContacts',
                text: 'Contacten',
                permissions: ['organization.contacts.menu']
            },
            {
                path: 'organizationConnectors',
                text: 'Data connectors',
                permissions: ['organization.responsibles.menu']
            },
            {
                path: 'Teams',
                text: 'Teams',
                permissions: ['organization.teams.menu']
            },
            {
                path: 'organizationIntegrations',
                text: 'Koppelingen',
                permissions: ['organization.integrations.menu']
            },
            {
                path: 'organizationSettings',
                text: 'Instellingen',
                permissions: ['organization.settings.menu']
            }
        ],
        },
        detail: {
            route: 'Team',
            endpoint: 'group',
            objectName: 'Group',
            fields: ['id', 'name', 'companies{id name}', 'projects{id name}', 'users{id, firstName, lastName, username, email, assignedRealmRoles{id name}, attributes{phone_number, jobtitle nickname birthdate gender locality locale iban bic postal_code street_address region country notes extra_email extra_phone}}'],
            tabs: [{
                    tab: 'general',
                    text: 'Algemeen'
                },{
                    tab: 'collaborators',
                    text: 'Medewerkers'
                },
                {
                    tab: 'companies',
                    text: 'Bedrijven'
                },
                {
                    tab: 'projects',
                    text: 'Projecten'
                },
            ],
        },
        export: {
            endpoint: 'groups',
            fields: ['id', 'name']
        },
        import: {
            endpoint: 'groups',
            objectName: 'Group',
        },
}