module.exports = {
    routes: [
        {
            path: '/organization/settings',
            name: 'organizationSettings',
            component: () => import('./views/detail.vue'),
            meta: {
                permissions: ['organization.settings.update']
            },
        }
    ],
    detail: {
        route: 'organization',
        endpoint: 'organization',
        objectName: 'Organization',
        fields: ['id', 'name', 'configuration{version}'],
        tabs: [{
                path: 'organization',
                text: 'Algemeen',
                permissions: ['organization.general.menu']
            },
            {
                path: 'organizationUsers',
                text: 'Medewerkers',
                permissions: ['organization.employees.menu']
            },
            {
                path: 'organizationContacts',
                text: 'Contacten',
                permissions: ['organization.contacts.menu']
            },
            {
                path: 'organizationConnectors',
                text: 'Data connectors',
                permissions: ['organization.responsibles.menu']
            },
            {
                path: 'Teams',
                text: 'Teams',
                permissions: ['organization.teams.menu']
            },
            {
                path: 'organizationIntegrations',
                text: 'Koppelingen',
                permissions: ['organization.integrations.menu']
            },
            {
                path: 'organizationSettings',
                text: 'Instellingen',
                permissions: ['organization.settings.menu']
            }
        ],
    },
}