<template>
    <div>
    <v-row class="mb-0 mt-0">
        <v-col cols="auto">
            <v-chip color="grey lighten-3" label>Vaste filters</v-chip>
        </v-col>
        <v-col>
            <template v-if="type == 'recursive'">
                <v-alert type="info" dense>Selecteer alle toestellen die u wilt weergeven in de grafiek.</v-alert>
                <eod-objects-treeview multiple :selected="value" @selectionChanged="changeRecursiveFilters"></eod-objects-treeview>
        </template>
            <v-menu offset-y v-else>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" color="grey"
                        icon><v-icon>mdi-plus-circle-outline</v-icon></v-btn>
                </template>
                <v-list>
                    <v-list-item
                        v-for="filter in Query.OPTIONS[source].filterOptions"
                        :key="filter.key" @click="addFilter(filter)">
                        <v-list-item-title>{{ filter.title }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </v-col>
                                        </v-row>
                                        <v-row v-if="type != 'recursive'" class="mb-0 mt-0" v-for="(filter, index) in groupedFilters()"
        :key="'filter_' + index">
        <v-col class="d-flex align-items-center" cols="auto">
            <v-chip v-if="filter.field.key != 'measurementType'" color="primary">{{
                filter.field.title }}</v-chip>
            <eod-object-autocomplete v-else
                :value="value[index].field.value"
                hide-details
                endpoint="measurementTypes"
                :fields="['id', 'name', 'unit{id name fields{name description}}']"
                @change="val => setValue(index, 'field.value', val)"></eod-object-autocomplete>
        </v-col>
        <v-col class="d-flex align-items-center" cols="auto">
            <v-chip v-if="filter.field.key != 'measurementType'">=</v-chip>
            <div style="width:70px;" v-else>
                <v-select hide-details outlined dense
                    :value="value[index].operator"
                    :items="Query.OPTIONS[source].operators"
                    item-text="title" item-value="key"
                    @change="val => setValue(index, 'operator', val)"></v-select>
            </div>
        </v-col>
        <v-col>
            <template v-if="filter.field.key != 'measurementType' && filter.field.endpoint">
                <div v-for="(filterVal, valIndex) in filter.values" class="d-flex align-items-center">
                    <eod-object-autocomplete
                    v-if="value[filterVal.index]"
                    :value="value[filterVal.index].value" hide-details
                    :endpoint="filter.field.endpoint"
                    :variables="variables?variables[filter.field.endpoint]:null"
                    :fields="filter.field.fields"
                    @change="val => setValue(filterVal.index, 'value', val, true)"></eod-object-autocomplete>
                    <v-btn @click="removeFilter(filterVal.index)" icon
                    color="error"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                    <v-btn :disabled="valIndex < filter.values.length-1" @click="addFilter(filter.field)" class="ml-2" small depressed outlined><v-icon left small>mdi-plus</v-icon>Of</v-btn>
                </div>
            </template>
            
            <v-select v-if="filter.field.key != 'measurementType' && filter.field.items"
                :items="filter.field.items"
                :value="value[index].value" @change="val => setValue(index, 'value', val, true)"
                dense outlined hide-details></v-select>
            <eod-listitem-autocomplete
                v-if="filter.field.list && $eod.getOrganizationSetting(filter.field.list)"
                :value="value[index].value" @change="val => setValue(index, 'value', val, true)"
                :listId="$eod.getOrganizationSetting(filter.field.list).value" dense
                outlined></eod-listitem-autocomplete>
            <v-text-field v-if="filter.field.key == 'measurementType'" hide-details
                dense :value="value[index].value" outlined
                @change="val => setValue(index, 'value', val, true)"></v-text-field>
        </v-col>
        <v-col class="d-flex align-items-center" cols="auto"  v-if="!(filter.field.key != 'measurementType' && filter.field.endpoint)">
            <v-btn @click="removeFilter(index)" icon
                color="error"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
        </v-col>
    </v-row>
</div>
</template>
<script>
import eodObjectsTreeview from './eod-objects-treeview.vue'
import eodListitemAutocomplete from './eod-list-item-autocomplete.vue'
import eodObjectAutocomplete from './eod-object-autocomplete.vue'
import Query from './../models/query';

export default {
    components:{
        eodListitemAutocomplete,
        eodObjectAutocomplete,
        eodObjectsTreeview,
    },
    props:{
        value: Array,
        type: {
            type: String,
            default: ''
        },
        source: {
            type: String,
            default: 'measurements'
        },
        variables: {
            type: Object,
            default: null
        }
    },
    data: () => ({
        Query: Query
    }),
    methods:{
        groupedFilters(){
            const filters = {};
            for (let i = 0; i < this.value.length; i++) {
                const filter = this.value[i];
                if(!filters[filter.field.key]){
                    filters[filter.field.key] = {
                        ...filter,
                        values: []
                    };
                }

                filters[filter.field.key].values.push({
                    value: filter.value,
                    index: i
                });
            }

            return filters;
        },
        changeRecursiveFilters(selection){
            this.$emit('input', selection);
            this.$emit('update');
        },
        addFilter(filter) {
            const val = this.value;
            val.push({
                field: filter,
                operator: '=',
                value: ''
            });

            this.$emit('input', val);
            this.$nextTick(() => {
                this.$forceUpdate();
            });
            
        },
        setValue(index, fieldname, value, update){
            const val = this.value;
            val[index][fieldname] = value;
            this.$emit('input', val);

            if(update){
                this.$emit('update');
            }
        },
        removeFilter(index) {
            const val = this.value;
            val.splice(index, 1);
            this.$emit('input', val);
            this.$emit('update');
            this.$nextTick(() => {
                this.$forceUpdate();
            });
        }
    }
}
</script>