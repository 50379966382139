<template>
    <v-alert v-if="item && (!isEmpty('type') || !isEmpty('vendor') || !isEmpty('description') || filledDetails[0])" color="grey lighten-4" class="text-body-2" dense>
        <v-row>
            <v-col cols="auto">
                <ul>
                    <li v-if="!isEmpty('type')">Type: <strong>{{item.type}}</strong></li>
                    <li v-if="!isEmpty('vendor')">Verkoper: <strong>{{item.vendor}}</strong></li>
                    <li v-if="!isEmpty('description')">Omschrijving: <strong>{{item.description}}</strong></li>
                </ul>
            </v-col>
            <v-col>
                <ul v-if="filledDetails[0]">
                    <li v-for="detail in filledDetails" :key="property">{{detail.property}}: <strong>{{detail.value}}</strong></li>
                </ul>
            </v-col>
        </v-row>
    </v-alert>
</template>
<script>
export default {
    name: 'eod-product-type-preview',
    props: {
        value: String
    },
    data: () => ({
        item: null
    }),
    created(){
        this.fetchItem();
    },
    watch: {
        value(){
            this.fetchItem();
        }
    },
    computed: {
        filledDetails(){
            const details = [];
            if(this.item && this.item.details){
                for (const key in this.item.details) {
                    const value = this.item.details[key];
                    if(value && value.trim() != ''){
                        details.push({
                            property: key,
                            value: value.trim()
                        });
                    }
                    
                }
            }
            
            return details;
        }
    },
    methods:{
        isEmpty(property){
            if(this.item){
                return !(this.item[property] && this.item[property].trim() != '');
            }

            return true;
        },
        fetchItem(){
            this.$eod.getById('productType', this.value, ['id','name', 'description','details', 'vendor', 'type'])
                .then(response => {
                    if(response && response.data.data && response.data.data.productType){
                        this.item = response.data.data.productType;
                    }
                });
        }
    }
}
</script>