<template>
<div>
    <v-row class="mb-0">
        <v-col>
        <v-autocomplete
        :search-input.sync="search"
        :loading="isUpdating"
        :readonly="isUpdating || readonly"
        :items="itemResults"
        label="Materiaal toevoegen"
        return-object
        item-text="name"
        v-model="searchItem"
        :solo="!flat?true:false"
        :outlined="flat?true:false"
        rounded
        auto-select-first
        no-filter
        @change="addItem"
        hide-details
        :item-disabled="itemDisabled"
        :dense="dense"
    >
    <template v-slot:item="{ item }">
        <v-list-item-title v-if="item.header">{{item.header}}</v-list-item-title>
        <v-list-item-content two-line v-else>
            <v-list-item-title v-if="item.name" v-text="item.name"></v-list-item-title>
            <v-list-item-subtitle v-if="item.description || item.quantity">
              <strong v-if="item.quantity">Aantal: {{item.quantity}}<span v-if="item.productType && item.productType.unit"> {{ item.productType.unit.name }}</span></strong>
              <span v-if="(item.quantity) && item.description"> | </span>
              <span v-if="item.description">{{item.description}}</span>
            </v-list-item-subtitle>
        </v-list-item-content>
      </template>
      <template v-if="showCreate" v-slot:append-item>
        <v-divider class="mb-2"></v-divider>
        <v-list-item @click.stop="showAddItem">
            <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
                <v-list-item-title>Nieuw materiaal aanmaken</v-list-item-title>
                <v-list-item-subtitle>Maak materiaal aan en voeg deze toe aan de lijst</v-list-item-subtitle>
            </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
        </v-col>
    </v-row>
<v-card :outlined="flat">
    <component :is="wide?'div':'v-card-text'">
        <div v-if="items && items.length > 0" >
            <v-list dense>
                <v-list-item v-for="item in items" :key="item.id">
                    <v-list-item-content>
                        <v-row>
                            <v-col class="d-flex flex-column justify-content-center">
                                <div>
                                <v-list-item-title v-text="item.name"></v-list-item-title>
                                </div>
                                <v-list-item-subtitle v-if="item.description && item.description != ''" v-text="item.description"></v-list-item-subtitle>
                            </v-col>
                            <v-col cols="12" sm="auto">
                                <div class="d-flex grey lighten-3 rounded-l rounded-r-lg">
                                <v-text-field v-if="showQuantity" :disabled="readonly" class="white" label="Aantal" dense outlined hide-details v-model="item.quantity" type="number" @input="$emit('input', items)"></v-text-field>
                                <div class="h-full d-flex align-items-center px-2 text--grey" v-if="item.productType && item.productType.unit">{{ item.productType.unit.name }}</div>
                            </div>
                            </v-col>
                        </v-row>
            </v-list-item-content>
            <v-list-item-icon v-if="!readonly">
                <eod-actions-column :item="item" :deleteAction="deleteItem" :showEdit="showEdit" :editAction="editItem"></eod-actions-column>
            </v-list-item-icon>
                </v-list-item>
            </v-list>
        </div>
        <v-list v-else>
            <v-list-item>
                <v-list-item-content>
                    <v-list-item-subtitle>Er is nog geen materiaal gekoppeld.</v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        
    </component>

    <v-dialog
      v-model="productDialog"
      persistent
      scrollable
      max-width="800px"
    >
      <eod-add-product v-model="editProduct" @onCancel="productDialog = false" @onSaved="itemSaved"></eod-add-product>
    </v-dialog>
</v-card>
</div>
</template>

<script>
import {Product} from '@/eodvuecomponents';
import eodErrorsDialog from './eod-errors-dialog.vue';
import eodColumnActions from './columns/eod-column-actions.vue';
import eodAddProduct from './eod-add-product.vue';
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import {VCardText} from 'vuetify/lib'

export default {
  name: 'eod-products',
  props: {
      value: Array,
      showQuantity:{
          type: Boolean,
          default: false
      },
      showStockLocation:{
          type: Boolean,
          default: false
      },
      showCreate:{
          type: Boolean,
          default: true
      },
      showEdit:{
          type: Boolean,
          default: true
      },
      flat:{
          type: Boolean,
          default: false
      },
      wide:{
          type: Boolean,
          default: false
      },
      dense:{
          type: Boolean,
          default: false
      },
      readonly:{
          type: Boolean,
          default: false
      },
      defaultStockLocation:{
          type: Object
      },
      suggested: Array
  },
  components:{
      'eod-add-product': eodAddProduct,
      'eod-actions-column': eodColumnActions,
      'eod-errors-dialog': eodErrorsDialog,
      'eod-listitem-autocomplete': eodListItemAutocomplete,
      VCardText
  },
  data(){
      return {
        search: '',
        isUpdating: false,
        searchResults: [],
        searchItem: null,
        timeout: null,
        productDialog: false,
        editProduct: new Product(),
      }
  },
  created(){
      this.items = this.value;
  },
  computed: {
      itemResults(){
          let items = [];
          if(this.suggested && this.suggested.length > 0){
              items.push({header: 'Voorgesteld'});
              for (let i = 0; i < this.suggested.length; i++) {
                  items.push(this.suggested[i]);
              }
          }

          if(this.searchResults.length > 0){
              items.push({header: 'Zoekresultaten'});
              for (let i = 0; i < this.searchResults.length; i++) {
                  items.push(this.searchResults[i]);
              }
          }

          return items;
      }
  },
  methods: {
      itemDisabled(item){
        if(this.items){
            for (let j = 0; j < this.items.length; j++) {
                if (item.id == this.items[j].id) {
                    this.$emit('input', this.items);
                    //this.$emit('changed', this.items);
                    return true;
                }
            }
        }
        return false;
      },
      deleteItem(item){
          for (let i = 0; i < this.items.length; i++) {
              if (this.items[i].id == item.id) {
                  this.items.splice(i, 1);
                  this.$emit('input', this.items);
                  this.$emit('changed', this.items);
                  this.$forceUpdate();
                  return;
              }
          }
      },
      editItem(item){
          this.editProduct = item;
          this.productDialog = true;
      },
      itemSaved(item){
        this.productDialog = false;

        let saveData = item.getSaveData();
        this.$eod.save('Product', saveData).then(result => {
            if (!item.id) {
              item.id = result.data.data.createProduct.id;
              this.addItem(item);
            }
        });
      },
      showAddItem(){
          this.editProduct = new Product();
          this.productDialog = true;
      },
      addItem(item){
        if (!this.items) {
            this.items = [];
        }

        if(this.defaultStockLocation){
            item.stockLocation = this.defaultStockLocation;
        }

        this.items.push(item);
        this.$emit('input', this.items);
        this.$emit('changed', this.items);
        
        this.$nextTick(() => {
            this.searchResults = [];
            this.search = '';
            this.searchItem = null;
            this.$forceUpdate();
        });
      }
  },
  watch: {
      search (val) {
        // Items have already been requested
        if (this.timeout) {
            clearInterval(this.timeout);
        }

        this.timeout = setTimeout(() => {
            if (this.isUpdating) return;

            if(!val || val == '' || val.length < 2){
                this.searchResults = [];
                return;
            };

            this.isUpdating = true;

            let variables = {
                where: [
                    {
                        column: 'name',
                        operator: 'ILIKE',
                        value: '%'+val+'%',
                        chain: 'or'
                    },
                    {
                        column: 'description',
                        operator: 'ILIKE',
                        value: '%'+val+'%',
                        chain: 'or'
                    }
                ],
            }

            // Filter products
            this.$eod.get('products', ['id', 'name', 'description', 'code', 'productType{unit{id name}}'], variables)
                .then(result => {
                    this.searchResults = [];
                    let count = result.data.data.products.edges.length;
                    for (let i = 0; i < count; i++) {
                        this.searchResults.push(new Product(result.data.data.products.edges[i]));
                    }
                    this.isUpdating = false;
                    this.$forceUpdate();
            });

            this.timeout = null;
        }, 500);

        
        
      },
    },
}
</script>