<template>
    <v-row>
        <v-col cols="12">
            <div class="d-flex">
            <eod-text-field type="number" step="1" label="Toegestane duur wegvallen communicatie"
                :value="value.content.deadman / value.content.divider" @input="val => updateVal('deadman', val)"
                clearable></eod-text-field>
                <eod-autocomplete :value="value.content.divider" @change="val => updateVal('divider', val)" :items="[{value:1000, text: 'Seconden'},{value:60000, text: 'Minuten'}]"></eod-autocomplete>
            </div>
        </v-col>
    </v-row>
</template>
<script>
export default {
    name: 'eod-alarm-detail-deadman',
    props: {
        value: {
            type: Object,
            default: () => ({})
        },
    },
    created() {
        let val = this.value;
        if (!val.content.deadman) {
            val.content.deadman = 120000;            
        }
        if (!val.content.divider) {
            val.content.divider = 60000;
        }

        this.$emit('input', val);
    },
    computed:{
        deadmanVal(){
            return this.value.content.deadman / this.value.content.divider;
        }
    },
    methods: {
        updateVal(property, value) {
            const val = this.value;
            if (val) {
                if(property == 'deadman'){
                    val.content.deadman = value * val.content.divider;
                } else {
                    val.content[property] = value;
                }
                this.$emit('input', val);
                this.$forceUpdate();
            }
        }
    }
}
</script>