export default class Base{
    static searchConfig = [{
        'icon': 'mdi-format-text',
        'label': 'Naam',
        'type': 'property',
        'settings': {
            'fields': ['name'],
            'title': '{name}',
        },
        'filter': 'name'
    },
    ];
    
    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }
    getSaveData() {
        let data = Object.assign({}, this);
        return data;
    }
}