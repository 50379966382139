module.exports = {
    routes: [{
        path: '/organization/integrations',
        name: 'organizationIntegrations',
        component: () => import('./views/detail.vue'),
        meta: {
            permissions: ['organization.integrations.read']
        }
    }],
    detail: {
        route: 'organization',
        endpoint: 'organization',
        objectName: 'Organization',
        fields: ['id', 'name', 'configuration{version settings{key value label isRoot isDefault}}'],
            tabs: [{
                path: 'organization',
                text: 'Algemeen',
                permissions: ['organization.general.menu']
            },
            {
                path: 'organizationUsers',
                text: 'Medewerkers',
                permissions: ['organization.employees.menu']
            },
            {
                path: 'organizationContacts',
                text: 'Contacten',
                permissions: ['organization.contacts.menu']
            },
            {
                path: 'organizationConnectors',
                text: 'Data connectors',
                permissions: ['organization.responsibles.menu']
            },
            {
                path: 'Teams',
                text: 'Teams',
                permissions: ['organization.teams.menu']
            },
            {
                path: 'organizationIntegrations',
                text: 'Koppelingen',
                permissions: ['organization.integrations.menu']
            },
            {
                path: 'organizationSettings',
                text: 'Instellingen',
                permissions: ['organization.settings.menu']
            }
        ],
    },
}