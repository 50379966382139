<template>
    <div>
        <v-expansion-panels class="mb-4" v-model="expanded">
            <v-expansion-panel v-for="event in events" :key="event.id">
                <v-expansion-panel-header>
                    <div style="line-height:1.2rem;">
                        <div :class="(Event.getSeverity(event.severity) ? Event.getSeverity(event.severity).color : 'grey')"
                            style="height:calc(100% - 24px);width:4px;position:absolute;top:12px;left:10px;"></div>
                        <div><strong>{{ $moment(event.time).format('DD-MM-YYYY HH[u]mm') }}:</strong>
                            {{ event.product ? event.product.name : '' }}</div>
                        <div v-if="event.owner && event.owner.getFullName"><v-icon small left>mdi-account</v-icon>{{
                            event.owner.getFullName()
                        }}</div>
                        <div><v-icon small left>mdi-comment-text</v-icon>{{ event.message }}</div>
                    </div>
                    <v-spacer></v-spacer>
                    <div style="flex:0;" class="mr-2">
                        <v-chip :color="Event.getStatus(event.status).color">{{
                            Event.getStatus(event.status).text
                        }}</v-chip>
                    </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>

                    <eod-event :value="event" @input="updateEvent" :readonly="readonly"></eod-event>
                    <div class="d-flex justify-content-end" v-if="!readonly">

                        <v-btn @click="showDeleteEventDialog(event)" class="mr-2" color="error" depressed rounded><v-icon
                                left>mdi-trash-can</v-icon>Verwijderen</v-btn>
                        <v-btn @click="saveEvent(event)" color="error" depressed rounded><v-icon
                                left>mdi-content-save</v-icon>Opslaan</v-btn>
                    </div>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        <v-btn v-if="!readonly" color="secondary" depressed rounded @click="addEvent"><v-icon left>mdi-plus</v-icon>Toevoegen</v-btn>

        <v-dialog v-model="deleteDialog" persistent max-width="550" v-if="eventToDelete != null">
            <v-card>
                <v-card-title class="mb-4">
                    <v-avatar color="error" size="40" class="elevation-3 mr-4">
                        <v-icon dark>
                            mdi-trash-can-outline
                        </v-icon>
                    </v-avatar><span class="headline">Verwijderen?</span>
                </v-card-title>
                <v-card-text>
                    <span class="text-body-1">Weet u zeker dat u de gebeurtenis op
                        '<strong>{{ $moment(eventToDelete.time).format('DD-MM-YYYY HH:mm') }}</strong>' wilt
                        verwijderen?</span>
                </v-card-text>
                <v-card-actions class="grey lighten-3 py-3 px-3">
                    <v-spacer></v-spacer>
                    <v-btn text @click="deleteDialog = false" rounded>Annuleren</v-btn>
                    <v-btn color="error" depressed rounded @click="deleteEvent(eventToDelete)">
                        <v-icon left>mdi-trash-can-outline</v-icon> Verwijderen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import eodListItemAutocomplete from './eod-list-item-autocomplete.vue';
import eodEvent from './eod-event.vue';
import Event from './../models/event';
import User from './../models/user';
import { readonly } from 'vue';

export default {
    name: 'eod-events',
    components: {
        eodListItemAutocomplete,
        eodEvent
    },
    props: {
        value: Array,
        filters: {
            type: Array,
            default: () => ([])
        },
        defaultEvent: {
            type: Object,
            default: () => ({})
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            expanded: null,
            fields: ['id', 'time', 'duration', 'product{id name}', 'category{id name}', 'project{id name}', 'company{id name}', 'owner{id}', 'type', 'status', 'userStatus', 'message', 'measurementType{id name}', 'severity', 'comments{content storageObjects{id key name} userId organizationId createdAt}'],
            events: [],
            Event: Event,
            deleteDialog: false,
            eventToDelete: null
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        updateEvent(event) {
            const index = this.getEventIndexById(event.id);
            this.events[index] = event;
            this.$emit('input', this.events);
        },
        async init() {

            let items = [];
            for (let i = 0; i < this.filters.length; i++) {
                const global_filters = this.filters[i];
                let filters = {
                    orderBy: {
                        column: 'time',
                        type: 'DESC'
                    }
                }
                if (global_filters) {
                    filters = {
                        ...filters,
                        ...global_filters
                    }
                }

                let result = await this.$eod.get('resolveEvents', this.fields, filters)
                    .then(response => {
                        return response.data.data.resolveEvents.edges;
                    });
                for (let j = 0; j < result.length; j++) {
                    const event = result[j];
                    items.push(event);
                }
            }

            let resolveUserIds = [];
            for (let i = 0; i < items.length; i++) {
                const event = items[i];
                if (event.owner && !resolveUserIds.includes(event.owner.id)) {
                    resolveUserIds.push(event.owner.id);
                }
            }

            let resolvedUsers = {};
            if (resolveUserIds.length > 0) {
                resolvedUsers = await this.$eod.get('resolveUsers', ['id', 'firstName', 'lastName', 'username'], {
                    whereIn: {
                        column: 'id',
                        array: resolveUserIds
                    }
                }).then(response => {
                    let users = response.data.data.resolveUsers.edges;
                    let usersById = {};
                    for (let i = 0; i < users.length; i++) {
                        const user = users[i];
                        usersById[user.id] = user;
                    }

                    return usersById;
                });
            }

            let events = [];
            for (let i = 0; i < items.length; i++) {
                const val = items[i];
                if (val.owner && resolvedUsers[val.owner.id]) {
                    val.owner = new User(resolvedUsers[val.owner.id]);
                }
                events.push(new Event(val));
            }

            this.events = events;
            this.$emit('input', events);
        },
        updateEventProperty(propertyName, val, event) {
            const index = this.getEventIndexById(event.id);
            this.events[index][propertyName] = val;
            this.$emit('input', this.events);
        },
        getEventIndexById(id) {
            for (let i = 0; i < this.events.length; i++) {
                const event = this.events[i];
                if (event.id == id) {
                    return i;
                }
            }

            return null;
        },
        deleteEvent(event) {
            this.$eod.delete('Event', event.id).then(() => {
                this.deleteDialog = false;
                this.eventToDelete = null;

                const index = this.getEventIndexById(event.id);
                this.events.splice(index, 1);

                this.$emit('input', this.events);
            });
        },
        showDeleteEventDialog(event) {
            this.eventToDelete = event;
            this.deleteDialog = true;
        },
        saveEvent(event) {
            const data = event.getSaveData();
            this.$eod.save('Event', data).then(response => {
                this.$emit('input', this.events);
                this.expanded = null;

                this.$nextTick(this.init);
            });
        },
        addEvent() {
            let event = {
                time: (new Date()).toISOString(),
                duration: 3600, // 1 hour in seconds
                ownerId: this.$eod.getUser().id,
                message: '',
                facility: 'user',
                severity: 'info',
                status: 'open',
                userStatus: 'unassigned',
                type: 'new',
                ...this.defaultEvent
            };

            this.$eod.save('Event', event, {
                create: {
                    returnfields: this.fields
                }
            }).then(response => {
                const event = new Event(response.data.data.createEvent);
                event.owner = this.$eod.getUser();
                this.events.push(event);
                this.expanded = this.events.length - 1;
                this.$emit('input', this.events);
                this.init();
            });

        }
    }
}
</script>