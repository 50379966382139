export default class Event {

    static searchConfig() {
        return [
            {
                'icon': 'mdi-format-text',
                'label': 'Bericht',
                'type': 'property',
                'filter': 'message'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Waarde',
                'type': 'property',
                'filter': 'value'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Commentaar',
                'type': 'property',
                'filter': 'comments'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Gemaskeerd',
                'type': 'options',
                'settings': {
                    options: [
                        {
                            value: 'false',
                            text: 'Nee'
                        },
                        {
                            value: 'true',
                            text: 'Ja'
                        }
                    ]
                },
                'filter': 'isMasked'
            },
            {
                'icon': 'mdi-waveform',
                'label': 'Type meetwaarde',
                'type': 'module',
                'settings': {
                    'module': 'measurementTypes',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'measurementTypeId'
            },
            {
                'icon': 'mdi-folder-open-outline',
                'label': 'Project',
                'type': 'module',
                'settings': {
                    'module': 'projects',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'projectId'
            },
            {
                'icon': 'mdi-office-building-outline',
                'label': 'Bedrijf',
                'type': 'module',
                'settings': {
                    'module': 'companies',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'companyId'
            },
            {
                'icon': 'mdi-file-sign',
                'label': 'Dienst',
                'type': 'module',
                'settings': {
                    'module': 'services',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'serviceId'
            },
            {
                'icon': 'mdi-package-variant',
                'label': 'Toestel',
                'type': 'module',
                'settings': {
                    'module': 'products',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'productId'
            },
            {
                'icon': 'mdi-account-outline',
                'label': 'Databron',
                'type': 'module',
                'settings': {
                    'module': 'searchUsers',
                    'fields': ['firstName', 'lastName'],
                    'title': '{firstName} {lastName}',
                    'options': {
                        enabled: true
                    }
                },
                'filter': 'ownerId'
            },
            {
                'icon': 'mdi-account-outline',
                'label': 'Gebruiker',
                'type': 'module',
                'settings': {
                    'module': 'searchUsers',
                    'fields': ['firstName', 'lastName'],
                    'title': '{firstName} {lastName}',
                    'options': {
                        enabled: true
                    }
                },
                'filter': 'responsibleUserId'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Onderdeel',
                'type': 'options',
                'settings': {
                    options: this.FACILITY_LIST
                },
                'filter': 'facility'
            },
            {
                'icon': 'mdi-form-select',
                'label': 'Jobtype',
                'type': 'module',
                'settings': {
                    'module': 'jobTypes',
                    'fields': ['name'],
                    'title': '{name}',
                },
                'filter': 'jobTypeId'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Type',
                'type': 'options',
                'settings': {
                    options: this.TYPES_LIST
                },
                'filter': 'type'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Severity',
                'type': 'options',
                'settings': {
                    options: this.SEVERITY_LIST
                },
                'filter': 'severity'
            },
            {
                'icon': 'mdi-format-text',
                'label': 'Status',
                'type': 'options',
                'settings': {
                    options: this.STATUS_LIST
                },
                'filter': 'status'
            },
        ];
    };

    static SEVERITY_LIST = [
        {
            value: 'crit',
            code: 2,
            text: 'Kritiek',
            color: '#DF2935'
        },
        {
            value: 'warning',
            code: 4,
            text: 'Waarschuwing',
            color: '#3FA7D6'
        },
        {
            value: 'ok',
            code: 8,
            text: 'OK',
            color: '#09BC8A'
        },
        {
            value: 'info',
            code: 6,
            text: 'Info',
            color: '#6C809A'
        },
        {
            value: 'alert',
            code: 1,
            text: 'Timeout',
            color: '#FF9F1C'
        },
    ];

    static TYPES_LIST = [
        {
            value: 'new',
            icon: 'mdi-bell-ring',
            text: 'Nieuw'
        },
        {
            value: 'dropped',
            icon: 'mdi-bell-minus-outline',
            text: 'Dropped'
        },
        {
            value: 'escalated',
            icon: 'mdi-bell-plus-outline',
            text: 'Escalated'
        },
        {
            value: 'acknowledged',
            icon: 'mdi-bell-check-outline',
            text: 'Acknowledged'
        },
        {
            value: 'unresolved',
            icon: 'mdi-bell-alert',
            text: 'Unresolved'
        },
        {
            value: 'comment',
            icon: 'mdi-comment-processing-outline',
            text: 'Comment'
        },
    ];

    static STATUS_LIST = [
        {
            value: 'open',
            text: 'Open',
            color: 'default',
            icon: 'mdi-checkbox-blank-circle-outline'
        },
        {
            value: 'cleared',
            text: 'Afgesloten',
            color: 'default',
            icon: 'mdi-circle-off-outline'
        }
    ]

    static FACILITY_LIST = [
        {
            value: 'system',
            text: 'Systeem',
        },
        {
            value: 'auth',
            text: 'Authenticatie',
        },
        {
            value: 'security',
            text: 'Beveiliging',
        },
        {
            value: 'console',
            text: 'Alarm',
        },
    ]

    static getStatus(status) {
        for (let i = 0; i < this.STATUS_LIST.length; i++) {
            const stat = this.STATUS_LIST[i];
            if (stat.value == status) {
                return stat;
            }
        }
        return null;
    }

    static getFacility(facility) {
        for (let i = 0; i < this.FACILITY_LIST.length; i++) {
            const fac = this.FACILITY_LIST[i];
            if (fac.value == facility) {
                return fac;
            }
        }
        return null;
    }

    static getSearchFilter(filterName) {
        for (let i = 0; i < this.searchConfig().length; i++) {
            const filter = this.searchConfig()[i];
            if (filter.filter == filterName) {
                return filter;
            }
        }
        return null;
    }

    static getType(type) {
        for (let i = 0; i < this.TYPES_LIST.length; i++) {
            const my_type = this.TYPES_LIST[i];
            if (my_type.value == type) {
                return my_type;
            }
        }
        return null;
    }

    static getSeverity(severity) {
        for (let i = 0; i < this.SEVERITY_LIST.length; i++) {
            const sev = this.SEVERITY_LIST[i];
            if (sev.value == severity) {
                return sev;
            }
        }
        return null;
    }

    static getSeverityByCode(severity_code) {
        for (let i = 0; i < this.SEVERITY_LIST.length; i++) {
            const sev = this.SEVERITY_LIST[i];
            if (sev.code == severity_code) {
                return sev;
            }
        }
        return null;
    }

    constructor(item) {
        if (item) {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    this[key] = item[key];
                }
            }
        }
    }

    getSaveData() {
        let data = Object.assign({}, this);

        const objectRelations = ['product', 'measurementType', 'company', 'project', 'owner', 'service', 'category', 'responsibleUser', 'alarmTemplate'];
        for (let i = 0; i < objectRelations.length; i++) {
            const relationObject = objectRelations[i];
            if (this.hasOwnProperty(relationObject)) {
                data[relationObject + 'Id'] = null;
                if (data[relationObject]) {
                    data[relationObject + 'Id'] = data[relationObject].id;
                }
                delete data[relationObject];
            }
        }

        if (data.hasOwnProperty('duration') && !data.status) {
            if (data.duration == null) {
                data.status = 'open';
            } else {
                data.status = 'cleared';
            }
        }

        return data;
    }
}
