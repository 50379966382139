const { MeasurementType } = require('../../eodvuecomponents');

module.exports = {
    routes: [
    {
        path: '/measurements/types',
        name: 'measurementTypes',
        meta:{
            permissions:['measurementtypes.read']
        },
        component: () => import('./types/index.vue')
    },
    {
        path: '/measurements/types/detail/:id?',
        name: 'measurementType',
        meta:{
            permissions:['measurementtypes.update', 'measurementtypes.create']
        },
        component: () => import('./types/detail.vue')
    }],
    types: {
        itemObject: MeasurementType,
        search: MeasurementType.searchConfig,
        index: {
            route: 'measurementTypes',
            endpoint: 'measurementTypes',
            fields: ['id', 'name', 'icon', 'unit{id name}', 'createdAt', 'updatedAt'],
            headers: [
                {
                    text: 'Icoon',
                    value: 'icon',
                    width:30,
                    sortable:false,
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-icon.vue')
                },
                {
                    text: 'Eenheid',
                    value: 'unit.name',
                    width:30,
                    sortable:false,
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: 'Naam',
                    value: 'name',
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-link.vue')
                },
                {
                    text: '',
                    width: 50,
                    value: 'createdAt',
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm-plus',
                        collapsed: true,
                        prepend: 'Aangemaakt op'
                    }
                }, {
                    text: '',
                    width: 50,
                    value: 'updatedAt',
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-date.vue'),
                    extra: {
                        icon: 'mdi-alarm',
                        collapsed: true,
                        prepend: 'Gewijzigd op'
                    }
                }, {
                    width: 120,
                    sortable: false,
                    component: () => import('./../../eodvuecomponents/src/components/columns/eod-column-actions.vue')
                }
            ],
            tabs: [{
                path: 'measurementTypes',
                text: 'Types'
            }],
        },
        detail: {
            route: 'measurementType',
            endpoint: 'measurementType',
            objectName: 'MeasurementType',
            fields: ['id', 'name', 'description', 'color', 'icon', 'unit{id}', 'typeConfig', 'isWritable', 'field', 'metricConfig{hardwareId dataType factor offset interval method}', 'valueConfigList{id name description itemType fields{name description isActive} listItems{id name description low high threshold stepSize fields{name description isActive}}}', 'dashboardConfig', 'formConfig{enabled required photo photoRequired comment commentRequired type category subCategory defaultValue values}'],
            tabs: [],
        },
        export: {
            endpoint: 'measurementTypes',
            fields: ['id', 'name', 'description']
        },
        import: {
            endpoint: 'MeasurementTypes',
            objectName: 'MeasurementType',
        },
    }
}