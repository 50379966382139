<template>
  <v-autocomplete :id="label?'autocomplete_'+$helper.slugify(label):''" ref="autocomplete" :loading="loading" @change="changed" :dense="dense" outlined :rules="rules"
    :label="label" :value="value && value.isVariable?value.id:value" :placeholder="placeholder" :search-input.sync="search" :items="filterItems"
    :item-text="getTitle" item-value="id" auto-select-first no-filter :return-object="returnObject" :readonly="readonly"
    :required="required" :disabled="disabled" :hide-details="hideDetails" :clearable="clearable">
    <template v-slot:prepend-item>
      <v-subheader>Zoekresultaten</v-subheader>
      <v-list-item v-if="!search || search == ''">
        <v-list-item-content>
          <v-list-item-title v-html="searchText"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="getTitle(item)"></v-list-item-title>
        <v-list-item-subtitle v-text="getDescription(item)"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:no-data>
      <v-list-item v-if="search && search != ''">
        <v-list-item-content>
          <v-list-item-title v-html="noDataText"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-else style="display:none;">
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <template v-if="variables && variables.length > 0">
        <v-subheader>Variabele</v-subheader>
        <v-list-item v-for="variable in variables" :key="variable.value"
          @click="variableClicked(variable)">
          <v-list-item-content>
            <v-list-item-title>{{ variable.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-if="resolvedSuggestions.length > 0">
        <v-subheader>Voorstel</v-subheader>
        <v-list-item v-for="suggestion in resolvedSuggestions" :key="suggestion.id"
          @click="suggestionClicked(suggestion)">
          <v-list-item-content>
            <v-list-item-title>{{ getTitle(suggestion) }}</v-list-item-title>
            <v-list-item-subtitle v-text="getDescription(suggestion)"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <slot name="append-item"></slot>
    </template>
  </v-autocomplete>
</template>
<script>
import User from './../models/user';
import _ from 'lodash';

export default {
  props: {
    label: String,
    noDataText: {
      type: String,
      default: 'Geen items gevonden met uw zoekopdracht'
    },
    searchText: {
      type: String,
      default: 'Zoek voor meer resultaten...'
    },
    value: Object | String,
    endpoint: String,
    placeholder: String,
    itemText: String,
    extraItems: {
      type: Object,
      default: () => { return {} }
    },
    exclude: {
      type: Array,
      default: () => {
        return [];
      }
    },
    fields: {
      type: Array,
      default: () => {
        return ['id', 'name', 'description'];
      }
    },
    readonly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    returnObject: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    suggestions: {
      type: Array,
      default: () => ([])
    },
    variables: {
      type: Array,
      default: () => ([])
    },
    defaultFilter: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    noData: false,
    search: '',
    items: [],
    timeout: null,
    loading: false,
    resolvedSuggestions: []
  }),
  mounted() {
    this.init(true);

    if (this.suggestions.length > 0) {
      this.$eod.get(this.endpoint, this.fields, {
        whereIn: {
          column: 'id',
          array: this.suggestions
        }
      }).then(response => {
        if (response.data.data) {
          this.resolvedSuggestions = response.data.data[this.endpoint].edges;
        }

      })
    }
  },
  computed: {
    filterItems() {
      let filterItems = [];
      if (this.items) {
        filterItems = this.items;
      }

      if (this.extraItems.start) {
        for (let i = this.extraItems.start.length - 1; i >= 0; i--) {
          const item = this.extraItems.start[i];
          filterItems.unshift(item);
        }
      }

      if (this.extraItems.end) {
        for (let i = 0; i < this.extraItems.end.length; i++) {
          const item = this.extraItems.end[i];
          filterItems.push(item);
        }
      }

      return filterItems;
    }
  },
  watch: {
    endpoint(val) {
      this.items = [];
    },
    search(val) {
      this.searchItems(val);
    },
    value(val, oldVal) {
      if (oldVal) {
        if (typeof val == 'string' && val == oldVal) {
          return false;
        } else if (val && val.id && oldVal && oldVal.id && val.id == oldVal.id) {
          return false;
        }
      }

      this.init();
    }
  },
  methods: {
    init(initial) {
      this.loading = true;

        let variables = {
          limit: 5
        };
        variables = _.mergeWith(variables, this.defaultFilter, this.customMerge);

        this.$eod.get(this.endpoint, this.fields, variables).then(result => {
          if (result.data.data) {
            this.resolvedSuggestions = this.excludeItems(result.data.data[this.endpoint].edges);
            if(!this.value && result.data.data[this.endpoint].edges.length == 1){
              const item = result.data.data[this.endpoint].edges[0];
              this.items = [item];
              if (this.returnObject) {
                  this.changed(item);
                } else {
                  this.changed(item.id);
                }
              this.value = item;
            }
          }
        }).then(() => {
          if (this.value){
              let ids = [];
              if (typeof this.value == 'string') {
                ids.push(this.value);
              } else if (Array.isArray(this.value)) {
                for (let i = 0; i < this.value.length; i++) {
                  const item = this.value[i];
                  ids.push(item.id);
                }
              } else if (this.value.isVariable && this.variables) {
                for (let i = 0; i < this.variables.length; i++) {
                  const variable = this.variables[i];
                  if(variable.value == this.value.id){
                    this.variableClicked(variable);
                    
                    break;
                  }
                }
                return false;
              } else if (this.value.id && this.value.name) {
                this.items = [this.value];
                return false;
              } else if (this.value.id) {
                ids.push(this.value.id);
              } else {
                return false;
              }

              this.loading = true;
              return this.$eod.get(this.endpoint, this.fields, {
                whereIn: {
                  column: 'id',
                  array: ids
                }
              }).then(result => {
                let results = result.data.data[this.endpoint].edges;
                this.items = results;

                if (initial && this.value) {
                  for (let i = 0; i < this.items.length; i++) {
                    const item = this.items[i];
                    if (typeof this.value == 'string' && item.id == this.value) {
                      if (this.returnObject) {
                        this.changed(item);
                      } else {
                        this.changed(item.id);
                      }
                    } else if (this.value.id && item.id == this.value.id) {
                      if (this.returnObject) {
                        this.changed(item);
                      } else {
                        this.changed(item.id);
                      }
                    }
                  }
                }
              }).finally(() => {
                this.loading = false;
              });
            }
          }
        ).finally(() => {
          this.loading = false;
        });
    },
    variableClicked(variable){

      const item = {
        isVariable: true,
        id: variable.value,
        name: 'VAR: ' + variable.text
      };

      this.items = [item];
      this.changed(item);

      this.value = item;

      this.$refs.autocomplete.isMenuActive = false;
    },
    suggestionClicked(suggestion) {

      const val = this.returnObject ? suggestion : suggestion.id;

      this.items = [suggestion];
      this.changed(val);

      this.value = val;

      this.$refs.autocomplete.isMenuActive = false;

    },
    getTitle(item) {
      if (item.name) {
        return item.name;
      } else if (item.firstName || item.username) {
        let usr = new User(item);
        return usr.getFullName();
      }

      return '';
    },
    getDescription(item) {
      if(this.endpoint == 'measurementTypes'){
        return (item.unit && item.unit.name?item.unit.name:'')
      } else if (item.description) {
        return item.description;
      } else if (item.firstName || item.username) {
        let usr = new User(item);
        if(usr.getRole()){
          return this.$t(usr.getRole().name);
        }
      }

      return null;
    },
    changed(e) {
      this.$emit('input', e);
      this.$emit('change', e);
    },
    excludeItems(results) {

      let my_ids = [];

      if (this.value) {
        if (typeof this.value == 'string') {
          my_ids.push(this.value);
        } else {
          for (let i = 0; i < this.value.length; i++) {
            const item = this.value[i];
            my_ids.push(item.id);
          }
        }
      }

      if (this.exclude.length > 0) {
        let exclude_ids = [];
        for (let i = 0; i < this.exclude.length; i++) {
          exclude_ids.push(this.exclude[i].id);
        }

        for (let i = 0; i < results.length; i++) {
          if (exclude_ids.includes(results[i].id) && !my_ids.includes(results[i].id)) {
            results.splice(i, 1);
          }
        }
      }

      return results;
    },
    customMerge(objValue, srcValue) {
      if (_.isArray(objValue)) {
        return objValue.concat(srcValue);
      }
    },
    searchItems(search) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      if (search == null) {
        this.noData = false;
        return false;
      }

      if (search == '') {
        this.noData = false;
        this.items = [];
        return false;
      }

      if(this.value && this.value.isVariable && search == this.value.name){
        for (let i = 0; i < this.variables.length; i++) {
            const variable = this.variables[i];
            if(variable.value == this.value.id){
              this.items=[{
                id: variable.value,
                name: variable.text
              }];
              break;
            }
          }
        this.items=[this.value];
        return false;
      }

      if(this.returnObject && this.value && this.value.name){
        if(this.value.name == search){
          this.items = [this.value];
          return false;
        }
      }

      this.timeout = setTimeout(() => {

        let variables = {};
        let fields = this.fields;

        if (this.endpoint == 'users' || this.endpoint == 'resolveUsers') {
          variables.search = search;
          fields = ['id', 'firstName', 'lastName', 'username', 'assignedRealmRoles{id name}'];
        } else {
          variables.where = [{
            column: 'name',
            operator: 'ILIKE',
            value: '%' + search + '%',
            chain: 'or'
          }, {
            column: 'description',
            operator: 'ILIKE',
            value: '%' + search + '%',
            chain: 'or'
          }];
        }

        variables = _.mergeWith(variables, this.defaultFilter, this.customMerge);

        this.loading = true;
        this.$eod.get(this.endpoint, fields, variables)
          .then(result => {
            this.items = [];
            let results = result.data.data[this.endpoint].edges;
            results = this.excludeItems(results);

            let count = results.length;

            if (count == 0) {
              this.noData = true;
            } else {
              this.noData = false;
              this.items = results;
            }

            this.timeout = null;

            this.$forceUpdate();

          }).finally(() => {
            this.loading = false;
          });
      }, 500);

    },
  }
}
</script>